export default () => ({
    faqs: [],
    noResults: null,

    init() {
        this.$root.querySelector('form.faq-search').addEventListener('submit', event => event.preventDefault());

        this.$root.querySelectorAll('.faq').forEach(element => {
            this.faqs.push({
                headline: element.querySelector('.faq-headline'),
                elements: Array.from(element.querySelectorAll('.faq-element')).map(elem => (
                    {
                        element: elem,
                        text: `${elem.querySelector('.faq-question').textContent.trim()} ${elem.querySelector('.faq-answer').textContent.trim()}`
                    }
                ))
            });
        });

        this.noResults = document.createElement('div');
        this.noResults.classList.add('no-results', 'hidden', 'text-center', 'text-gray-500', 'mt-4');
        this.noResults.textContent = 'Keine Ergebnisse gefunden.';
        this.$root.appendChild(this.noResults);
    },

    search(event) {
        const searchValue = event.target.value.trim();

        if (searchValue === '') {
            this.faqs.forEach(faq => {
                faq.headline?.classList.remove('hidden');
                faq.elements.forEach(element => element.element.classList.remove('hidden'));
            });

            return;
        }

        this.faqs.forEach(faq => {
            faq.elements.forEach(element => {
                if (element.text.toLowerCase().includes(searchValue.toLowerCase())) {
                    element.element.classList.remove('hidden');
                } else {
                    element.element.classList.add('hidden');
                }
            });

            if (faq.elements.some(element => !element.element.classList.contains('hidden'))) {
                faq.headline?.classList.remove('hidden');
            } else {
                faq.headline?.classList.add('hidden');
            }
        });

        if (this.faqs.filter(faq => faq.elements.some(element => !element.element.classList.contains('hidden'))).length === 0) {
            this.noResults.classList.remove('hidden');
        } else {
            this.noResults.classList.add('hidden');
        }
    }
});
