export default () => ({
    wrapper: null,
    placeholder: null,

    init() {
        this.set();
        Livewire.on('form:update', () => this.set());
    },

    set() {
        if (this.wrapper === null) {
            this.wrap();
        }

        this.startDate = document.getElementById('startDate');
        this.endDate = document.getElementById('endDate');

        this.$root.addEventListener('focus', event => {
            try {
                this.$root.showPicker();
            } catch (error) {
            }
        });

        this.$root.addEventListener('blur', event => {
            this.$root.classList.toggle('!opacity-100', event.target.value.length);
            this.placeholder.classList.toggle('!opacity-0', event.target.value.length);

            if (event.target.value.length && this.$root.id === 'startDate' && document.getElementById('endDate')) {
                document.getElementById('endDate').setAttribute('min', event.target.value);
            }
        });

        this.$root.dispatchEvent(new Event('blur'));
    },

    wrap() {
        this.wrapper = document.createElement('span');
        this.wrapper.classList.add('relative', 'block');
        Array.from(this.$root.classList).forEach(className => {
            if (className.includes('w-') || className.includes('h-')) {
                this.wrapper.classList.add(className);
                this.$root.classList.remove(className);
            }
        });

        this.placeholder = document.createElement('span');
        this.placeholder.classList.add('absolute', 'z-10', 'top-0', 'left-0', 'text-gray-400', 'bg-white', 'w-full', 'h-full', 'flex', 'items-center', 'px-2', 'border', 'border-gray-300');
        this.placeholder.innerHTML = this.$root.getAttribute('placeholder');

        this.$root.classList.add('absolute', 'z-20', 'top-0', 'left-0', 'opacity-0', 'focus:opacity-100', 'cursor-text', 'w-full', 'h-full');

        this.$root.parentNode.insertBefore(this.wrapper, this.$root);
        this.wrapper.appendChild(this.placeholder);
        this.wrapper.appendChild(this.$root);
    }
});
